
import { defineComponent, onMounted } from "vue";
import ECP_EDP_DashboardNavigator from "@/components/cards/ECP_EDP_DashboardNavigator.vue"
import {getPGIModelData, claimPGI} from "@/api/pgi"
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import {isPGITop20Qualified} from "@/api/dashboard"

export default defineComponent({
  name: "widgets-feeds",
  components: {
    ECP_EDP_DashboardNavigator
  },
  data(){
    return {
      pgitop20products : [],
      travel_points : 0,
      history:[],
      isLoading:true,
      isPGITop20Qualified:false,
    }
  },
  methods:{
    async checkIfPGITop20Qualified(){
      const response = await isPGITop20Qualified();
      this.isPGITop20Qualified = response.data.isPGITop20Qualified;
    },
    async getPGIModelData(){
      this.isLoading = true;
      const response = await getPGIModelData(1);
      this.pgitop20products = response.data.pgitop20products;
      this.travel_points = response.data.travel_points;
      this.history = response.data.historytop20;
      this.isLoading = false;
    },
    async claimPGI(pgicode){
      this.isLoading = true;
      let param = {"pgicode" : pgicode}
      const response = await claimPGI(param);
      if(response != null){
          this.isLoading = false;
          
          if (response.data.result == "SUCCESS") {
            Swal.fire({
              title: "",
              text: response.data.message,
              icon: "success",
              confirmButtonClass: "btn btn-secondary"
            }); 
            window.location.reload();
            return; 
          } else if(response.data.result == "FAILED"){
            Swal.fire({
              title: "",
              text: response.data.message,
              icon: "error",
              confirmButtonClass: "btn btn-secondary"
            }); 
            return; 
          }
        }
    }
  },
  mounted(){
    this.getPGIModelData();
    this.checkIfPGITop20Qualified();
  }
});
